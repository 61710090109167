/** @jsxRuntime classic */
/** @jsx jsx */
import { Link } from "gatsby"
import { Button, Text, jsx } from "theme-ui"

import Arrow from "components/common/Arrow"

interface AguilaButtonProps {
  //enables sx prop to be passed down from parent
  className?: string
  /**
   * Link text.
   */
  text?: string
  /**
   * Where to link to.
   */
  url?: string
  /**
   * Button Variant - theme-ui buttons.variant
   */
  variant?: "primary" | "secondary" | "textOnly"
  callback?: () => void
  state?: Object
  type?: "button" | "submit" | "reset" | undefined
}

const AguilaButton = ({
  className,
  text,
  url,
  variant = "primary",
  callback,
  state,
  type,
}: AguilaButtonProps) => {
  if (url) {
    const internal = /^\/(?!\/)/.test(url)

    if (internal) {
      return (
        <Link
          to={url}
          state={state}
          className={className + " local"}
          sx={{ variant: `buttons.${variant}` }}
        >
          <Text as="span" className="link-text">
            {text}
          </Text>
          {(variant === "secondary" || variant === "textOnly") && (
            <Arrow
              arrowColor={variant === "secondary" ? "textWhite" : "primary"}
            />
          )}
        </Link>
      )
    } else {
      return (
        <a
          className={className}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            variant: `buttons.${variant}`,
          }}
        >
          <Text as="span" className="link-text">
            {text}
          </Text>
          {(variant === "secondary" || variant === "textOnly") && (
            <Arrow
              arrowColor={variant === "secondary" ? "textWhite" : "primary"}
            />
          )}
        </a>
      )
    }
  } else if (callback) {
    return (
      <Button variant={variant} className={className} onClick={callback}>
        <Text as="span" className="link-text">
          {text}
        </Text>
        {(variant === "secondary" || variant === "textOnly") && (
          <Arrow
            arrowColor={variant === "secondary" ? "textWhite" : "primary"}
          />
        )}
      </Button>
    )
  } else {
    return (
      <Button variant={variant} className={className} type={type}>
        <Text as="span" className="link-text">
          {text}
        </Text>
        {(variant === "secondary" || variant === "textOnly") && (
          <Arrow
            arrowColor={variant === "secondary" ? "textWhite" : "primary"}
          />
        )}
      </Button>
    )
  }
}

export default AguilaButton
