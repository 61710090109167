/** @jsx jsx */
import { jsx } from "theme-ui"

type arrowProps = {
  arrowColor?: string
  reverse?: boolean
  className?: string
}

const Arrow = ({ arrowColor = "inherit", reverse, className }: arrowProps) => {
  return (
    <svg
      className={`link-arrow ${className}`}
      width="7"
      height="13"
      viewBox="0 0 7 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        transition: "all 0.3s ease-in-out",
        fill: arrowColor,
        display: "inline-block",
        flex: "0 0 7px",
        width: "7px",
        height: "13px",
        transform: reverse ? "scale(-1,1)" : "scale(1,1)",
      }}
    >
      <path
        d="M-8.11783e-08 11.1429L4.375 6.5L-4.8707e-07 1.85714L0.874999 -3.82475e-08L7 6.5L0.875 13L-8.11783e-08 11.1429Z"
        fill="inherit"
      />
    </svg>
  )
}

export default Arrow
